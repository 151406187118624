import css from "styled-jsx/css";
import { Breakpoint } from "@/app/lib/styles";

export const paperBoxImgStyles = css`
  .paper-box-img {
    margin: 0 0 10px;
  }

  @media (min-width: ${Breakpoint.TABLET}) {
    .paper-box-img {
      margin: 0 0 20px;
    }
  }

  @media (min-width: ${Breakpoint.DESKTOP}) {
    .paper-box-img {
      margin: -20px 0 20px;
    }
  }
`;

export const paperBoxImgGlobalStyles = css.global`
  .paper-box-img svg {
    width: 100%;
    height: auto;
    max-width: 380px;
    max-height: 180px;
  }
`;
