import React, { ReactNode } from "react";
import { paperBoxStyles } from "./PaperBox.styles";

export const PaperBox = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div className="paper-box">{children}</div>
      <style jsx>{paperBoxStyles}</style>
    </>
  );
};
