import css from "styled-jsx/css";
import { Breakpoint } from "@/app/lib/styles";

export const styles = css`
  .section-header {
    margin: 30px 0;
  }

  .section-header--big {
    margin: 40px 0;
  }

  .section-header--small {
    margin: 20px 0;
  }

  @media (min-width: ${Breakpoint.DESKTOP}) {
    .section-header {
      margin: 40px 0;
    }

    .section-header--big {
      margin: 80px 0;
    }

    .section-header--small {
      margin: 30px 0;
    }
  }

  .section-header--clear-top-space {
    margin-top: 0;
  }

  .section-header--clear-bottom-space {
    margin-bottom: 0;
  }
`;
