"use client";

import {
  ErrorPageStyles,
  ErrorPageGlobalStyles,
} from "./ErrorPageContainer.styles";
import {
  PaperBox,
  PaperBoxContent,
} from "@/app/lib/components/commons/PaperBox";
import {
  Section,
  SectionSize,
  SectionHeader,
} from "@/app/lib/components/commons/Section";
import ErrorSVG from "@/app/lib/static/svg/errorPage/404.svg";
import { Caption } from "../commons/Layout/Caption/Caption";
import { Header } from "../commons/Layout/Header/Header";
import { Button } from "@mui/material";

export const ErrorPageContainer = ({ statusCode }: { statusCode: number }) => {
  const renderErrorMessage = () => {
    switch (statusCode) {
      case 403: {
        return "Brak wymaganych uprawnień.";
      }
      case 404: {
        return "Niestety, strona o podanym adresie nie istnieje. By przejść do świata ciekawych emisji kliknij…";
      }
      default: {
        return "Wystąpił nieoczekiwany bład. Prosimy spróbować później";
      }
    }
  };

  return (
    <>
      <Section size={SectionSize.big}>
        <div className="error-page">
          <PaperBox>
            <PaperBoxContent>
              <div className="error-page__content">
                <div className="error-page__item error-page__item--image">
                  <ErrorSVG />
                </div>
                <div className="error-page__line"></div>
                <div className="error-page__item">
                  <Caption>Ups... {statusCode}</Caption>
                  <SectionHeader clearTopSpace={false}>
                    <Header isLine={false}>Jakoś tu pusto!</Header>
                  </SectionHeader>
                  <div className="error-page__desciption">
                    {renderErrorMessage()}
                  </div>
                  <div className="error-page__footer">
                    <a href={"/"}>
                      <Button variant="contained">Wróć do Emiteo</Button>
                    </a>
                  </div>
                </div>
              </div>
            </PaperBoxContent>
          </PaperBox>
        </div>
      </Section>
      <style jsx>{ErrorPageStyles}</style>
      <style jsx global>
        {ErrorPageGlobalStyles}
      </style>
    </>
  );
};
