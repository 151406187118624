"use client";

import React from "react";
import { styles } from "./Section.styles";

export enum SectionSize {
  clear = "clear",
  small = "small",
  medium = "medium",
  big = "big",
}

interface SectionProps {
  children: React.ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  isIndentation?: boolean;
  size?: SectionSize;
}

export const Section = ({
  children,
  isFirst = false,
  isLast = false,
  isIndentation = false,
  size = SectionSize.small,
}: SectionProps) => {
  const className = () =>
    [
      ...(isFirst ? ["section--is-first"] : []),
      ...(isLast ? ["section--is-last"] : []),
      ...(isIndentation ? ["section--is-indentation"] : []),
      `section--size-${size}`,
    ].join(" ");

  return (
    <>
      <div className={`section ${className()}`}>{children}</div>
      <style jsx>{styles}</style>
    </>
  );
};
