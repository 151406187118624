"use client";

import React from "react";
import { styles } from "./SectionHeader.styles";

export enum SectionHeaderSize {
  DEFAULT = "default",
  BIG = "big",
}

interface SectionHeaderProps {
  children: React.ReactNode;
  size?: SectionHeaderSize;
  clearTopSpace?: boolean;
  clearBottomSpace?: boolean;
}

export const SectionHeader = ({
  children,
  size = SectionHeaderSize.DEFAULT,
  clearTopSpace = true,
  clearBottomSpace = false,
}: SectionHeaderProps) => {
  const className = () =>
    [
      ...(size ? [`section-header--${size}`] : []),
      ...(clearTopSpace ? [`section-header--clear-top-space`] : []),
      ...(clearBottomSpace ? [`section-header--clear-bottom-space`] : []),
    ].join(" ");

  return (
    <>
      <div className={`section-header ${className()}`}>{children}</div>
      <style jsx>{styles}</style>
    </>
  );
};
