import React, { ReactNode } from "react";
import { paperBoxContentStyles } from "./PaperBoxContent.styles";

export const PaperBoxContent = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div className="paper-box-content">{children}</div>
      <style jsx>{paperBoxContentStyles}</style>
    </>
  );
};
