import css from "styled-jsx/css";
import { Breakpoint } from "@/app/lib/styles";

export const styles = css`
  .section {
    width: 100%;
    padding: 20px 0;
  }

  .section--size-medium {
    padding: 30px 0;
  }

  .section--size-big {
    padding: 40px 0;
  }

  @media (min-width: ${Breakpoint.DESKTOP}) {
    .section {
      padding: 32px 0 20px;
    }

    .section--size-medium {
      padding: 60px 0;
    }

    .section--size-big {
      padding: 100px 0;
    }

    .section--is-indentation {
      padding-left: 140px;
    }
  }

  .section--is-first {
    padding-top: 0;
  }

  .section--is-last {
    padding-bottom: 0;
  }

  .section--clear {
    padding: 0;
  }
`;
