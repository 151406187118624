import css from "styled-jsx/css";
import { Breakpoint, fontSize, fontWeight, color } from "@/app/lib/styles";

export const captionStyles = css`
  .caption {
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.default};
    text-transform: uppercase;
    color: ${color.secondary};
  }

  @media (min-width: ${Breakpoint.DESKTOP}) {
    .caption {
      font-size: ${fontSize.big};
    }
  }
`;
