import React from "react";
import { paperBoxBadgeStyles } from "./PaperBoxBadge.styles";
import AlertSVG from "@/app/lib/static/svg/investStatus/alert.svg";
import CheckSVG from "@/app/lib/static/svg/investStatus/check.svg";

export enum PaperBoxBadgeType {
  default = "default",
  success = "success",
  failure = "failure",
}

interface PaperBoxBadgeProps {
  children: React.ReactNode;
  type?: PaperBoxBadgeType;
  icon?: React.ReactNode;
}

export const PaperBoxBadge = ({
  children,
  type = PaperBoxBadgeType.default,
  icon,
}: PaperBoxBadgeProps) => {
  return (
    <>
      <div className={`paper-box-badge paper-box-badge--${type}`}>
        <div className="paper-box-badge__icon">
          {icon && icon}
          {!icon && type === PaperBoxBadgeType.failure && <AlertSVG />}
          {!icon && type === PaperBoxBadgeType.success && <CheckSVG />}
        </div>
        <div className="paper-box-badge__text">{children}</div>
      </div>
      <style jsx>{paperBoxBadgeStyles}</style>
    </>
  );
};
