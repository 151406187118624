import css from "styled-jsx/css";
import { Breakpoint, fontSize, fontWeight } from "@/app/lib/styles";
import { gradient } from "@/app/lib/styles/gradients";

export const headerStyles = css`
  .header-c {
    display: flex;
    align-items: center;
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.subtitle};
  }

  .header-c__line {
    display: none;
    width: 60px;
    min-width: 60px;
    height: 1px;
    margin-right: 20px;
    background: ${gradient.primary};
  }

  .header-c__content {
    width: 100%;
  }

  .header-c--is-center {
    text-align: center;
  }

  @media (min-width: ${Breakpoint.MOBILE}) {
    .header-c--is-line {
      .header-c__line {
        display: block;
      }
    }
  }

  @media (min-width: ${Breakpoint.TABLET}) {
    .header-c__line {
      width: 100px;
      min-width: 100px;
      margin-right: 40px;
    }
  }

  @media (min-width: ${Breakpoint.DESKTOP}) {
    .header-c {
      font-size: ${fontSize.title};
    }
  }
`;
