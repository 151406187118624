import React, { ReactNode } from "react";
import {
  paperBoxImgStyles,
  paperBoxImgGlobalStyles,
} from "./PaperBoxImg.styles";

export const PaperBoxImg = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div className="paper-box-img">{children}</div>
      <style jsx>{paperBoxImgStyles}</style>
      <style jsx>{paperBoxImgGlobalStyles}</style>
    </>
  );
};
