import css from "styled-jsx/css";
import { Breakpoint } from "@/app/lib/styles";

export const paperBoxContentStyles = css`
  .paper-box-content {
    width: 100%;
    padding: 40px 20px;
  }

  @media (min-width: ${Breakpoint.TABLET}) {
    .paper-box-content {
      padding: 50px 30px;
    }
  }

  @media (min-width: ${Breakpoint.DESKTOP}) {
    .paper-box-content {
      padding: 80px 50px;
    }
  }
`;
