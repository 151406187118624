import css from "styled-jsx/css";
import { color, radius, shadow } from "@/app/lib/styles";

export const paperBoxStyles = css`
  .paper-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    background-color: ${color.white};
    border-radius: ${radius.default};
    box-shadow: ${shadow.default};
    overflow: hidden;
  }
`;
