import React, { ReactNode } from "react";
import { captionStyles } from "./Caption.styles";

export const Caption = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div className="caption">{children}</div>
      <style jsx>{captionStyles}</style>
    </>
  );
};
