import css from "styled-jsx/css";
import { Breakpoint, fontSize } from "@/app/lib/styles";
import { gradient } from "@/app/lib/styles/gradients";

export const ErrorPageStyles = css`
  .error-page {
    margin: 0 auto;
    max-width: 1080px;
    line-height: 1.8em;
    text-align: center;
    font-size: ${fontSize.big};
  }

  .error-page__footer {
    margin-top: 25px;
  }

  .error-page__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: -15px;
  }

  .error-page__item {
    margin: 15px;
  }

  .error-page__desciption {
    max-width: 400px;
    margin: 0 auto;
  }

  .error-page__line {
    display: none;
    align-self: flex-start;
    margin-top: 140px;
    width: 100px;
    min-width: 100px;
    height: 2px;
    margin-right: 20px;
    background: ${gradient.primary};
  }

  .error-page__item--image {
    width: 100%;
    max-width: 300px;
  }

  @media (min-width: ${Breakpoint.TABLET}) {
    .error-page__content {
      flex-direction: row;
      text-align: left;
    }

    .error-page__content {
      margin: 0;
    }

    .error-page__item {
      margin: 20px;
    }

    .error-page__desciption {
      margin: 0;
    }
  }

  @media (min-width: ${Breakpoint.DESKTOP}) {
    .error-page__footer {
      margin-top: 35px;
    }

    .error-page__line {
      display: block;
    }
  }
`;

export const ErrorPageGlobalStyles = css.global`
  .error-page svg {
    width: 100%;
    max-width: 300px;
  }

  @media (min-width: ${Breakpoint.TABLET}) {
    .error-page svg {
      min-width: 240px;
    }
  }

  @media (min-width: ${Breakpoint.DESKTOP}) {
    .error-page svg {
      min-width: 300px;
    }
  }
`;
