import css from "styled-jsx/css";
import { color, fontWeight } from "@/app/lib/styles";

export const paperBoxBadgeStyles = css`
  .paper-box-badge {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 0;
    font-weight: ${fontWeight.bold};
  }

  .paper-box-badge__text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  .paper-box-badge__icon {
    display: flex;
    padding: 8px;
  }

  .paper-box-badge--default {
    color: ${color.secondaryDark};
    background-color: ${color.secondaryLight};
  }

  .paper-box-badge--success {
    color: ${color.success};
    background-color: ${color.successLight};
  }

  .paper-box-badge--failure {
    color: ${color.failure};
    background-color: ${color.failureLight};
  }
`;
