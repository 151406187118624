"use client";

import React, { ReactNode } from "react";
import { headerStyles } from "./Header.styles";

interface HeaderProps {
  children: ReactNode;
  isLine?: boolean;
  isCenter?: boolean;
}

export const Header = ({
  children,
  isLine = true,
  isCenter = false,
}: HeaderProps) => {
  const className = () =>
    [
      ...(isCenter ? ["header-c--is-center"] : []),
      ...(isLine ? ["header-c--is-line"] : []),
    ].join(" ");

  return (
    <>
      <div className={`header-c ${className()}`}>
        <span className="header-c__line"></span>
        <div className="header-c__content">{children}</div>
      </div>
      <style jsx>{headerStyles}</style>
    </>
  );
};
